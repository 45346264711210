import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes, Outlet, Navigate } from 'react-router-dom'
import axios from 'axios'
import './scss/style.scss'
import Device2 from './views/pages/axle/Device2'
import ReadingCount from './views/pages/axle/ReadingCount'
import MapView from './views/pages/axle/MapView'
import Contact from './views/pages/axle/Contact'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
// const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Division = React.lazy(() => import('./views/pages/axle/Division'))
const TempSetting = React.lazy(() => import('./views/pages/axle/TempSetting'))
const Station = React.lazy(() => import('./views/pages/axle/Station'))
const SmsAlert = React.lazy(() => import('./views/pages/axle/SmsAlert'))
const Device = React.lazy(() => import('./views/pages/axle/Device'))
const AlertHistory = React.lazy(() => import('./views/pages/axle/AlertHistory'))
const Zone = React.lazy(() => import('./views/pages/axle/Zone'))
const AlertColorConfig = React.lazy(() => import('./views/pages/axle/AlertColorConfig'))
const HabdHistory = React.lazy(() => import('./views/pages/axle/HabdHistory'))
const DeviceReading = React.lazy(() => import('./views/pages/axle/DeviceReading'))
const DeviceReadingDevice = React.lazy(() => import('./views/pages/axle/DeviceReadingDevice'))
const SearchTable2 = React.lazy(() => import('./views/pages/axle/SearchTable2'))
const DeviceStatus = React.lazy(() => import('./views/pages/axle/DeviceStatus'))

const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const DeviceDetails = React.lazy(()=>import("./views/pages/axle/DeviceDetails"))
const setAuthToken = (token) => {
   if (token) {
       axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
   }
   else
       delete axios.defaults.headers.common["Authorization"];
}

const AuthWrapper = () => {
  var token = localStorage.getItem('token');
  if(token) {
      const decode = JSON.parse(atob(token.split('.')[1]));
      // console.log(decode.exp * 1000);
      // console.log(new Date().getTime());
      if (decode.exp * 1000 < new Date().getTime()) {
            token = '';
            localStorage.setItem("token", '');
            console.log('Time Expired');
        }
  }
  setAuthToken(token);
  return token
    ? <Outlet />
    : <Navigate to="/login" replace />;
};

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route element={<AuthWrapper />}>
                <Route exact path="/division" name="Division" element={<Division />} />
                <Route exact path="/temp-setting" name="Temperature Setting" element={<TempSetting />} />
                <Route exact path="/station" name="Station" element={<Station />} />
                <Route exact path="/sms-alert" name="SMS Alert" element={<SmsAlert />} />
                <Route exact path="/device" name="Device" element={<Device />} />
                <Route exact path="/alert-history" name="Alert History" element={<AlertHistory />} />
                <Route exact path="/zone" name="Zone" element={<Zone />} />
                <Route exact path="/alert-conf" name="Alert Config" element={<AlertColorConfig />} />
                <Route exact path="/habd-history" name="HABD History" element={<HabdHistory />} />
                <Route exact path="/device-reading" name="Device Reading" element={<DeviceReading />} />
                <Route exact path="/device-reading-device" name="Device Reading" element={<DeviceReadingDevice />} />
                <Route exact path="/table2" name="Search Table2" element={<SearchTable2 />} />
                <Route exact path="/device_status" name="Device Status" element={<DeviceStatus />} />
                <Route exact path="/reading-overview" name="Reading Overview" element={<Device2 />} />
                <Route exact path="/statistics-report" name="Statistics report" element={<ReadingCount />} />
                <Route exact path="/map-view" name="Map View" element={<MapView />} />
                <Route exact path="/contact" name="Contact" element={<Contact />} />
                <Route exact path="/device-details" name="Device Details" element={<DeviceDetails />} />
            </Route>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            {/* <Route path="*" name="Home" element={<DefaultLayout />} /> */}
            <Route path="*" name="Login Page" element={<Login />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
